<template>
    <div>

        <h1 class="text-h5 font-weight-medium" style="cursor: pointer;" @click="$router.push(getLink(blog.slugUrl))">
            {{ blog.title }}</h1>
        <div class="grey--text text--darken-2">{{ getDateDisplay(blog.date) }} - {{ blog.durationInMinute }} minutes de lecture
        </div>

        <p class="mt-3" style="cursor: pointer;" @click="$router.push(getLink(blog.slugUrl))"
            v-html="blog.shortHtmlDescription"></p>

        <p class="grow d-flex justify-end">
            <router-link :to="getLink(blog.slugUrl)">Lire la suite</router-link>
        </p>
    </div>
</template>

<script>

import datehelper from '@/helpers/datehelper'

export default {
    name: "BlogItemShortContent",
    components: {},
    props: ['blog'],
    data() {
        return {
        };
    },
    methods: {
        getReadDuration() {
            // var html = blog.;
            // var div = document.createElement("div");
            // div.innerHTML = html;
            // var text = div.textContent || div.innerText || "";
            // console.log(text)
        },
        getLink(url) {
            return `/${this.$i18n.locale}/${url}`;
        },
        getDateDisplay(date) {
            return datehelper.getFullDateDisplay(date);
        },
    },
    mounted() {
    },
};
</script>
<template>
    <v-img style="cursor: pointer;" @click="$router.push(getLink(blog.slugUrl))" :src="getImageUrl()" class="rounded-lg"
        contain />
</template>

<script>

import datehelper from '@/helpers/datehelper'

export default {
    name: "BlogItemShortContent",
    components: {},
    props: ['blog'],
    data() {
        return {
        };
    },
    methods: {
        isValidHttpUrl(string) {
            let url;
            try {
                url = new URL(string);
            } catch (_) {
                return false;
            }
            return url.protocol === "http:" || url.protocol === "https:";
        },
        getImageUrl() {
            return this.isValidHttpUrl(this.blog.imageUrl) ? this.blog.imageUrl : `/static/blog/${this.blog.imageUrl}`;
        },
        getLink(url) {
            return `/${this.$i18n.locale}/${url}`;
        },
        getDateDisplay(date) {
            return datehelper.getFullDateDisplay(date);
        },
    },
    mounted() {
    },
};
</script>
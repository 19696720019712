<template>
  <div>
    <v-container class="pb-12" v-for="(blog, index) in blogs" :key="blog.id">

      <template v-if="index % 2 == 1 && !$helpers.display.isMobile">
        <v-row justify="center">
          <v-col md="8">
            <BlogItemShortContent :blog="blog" />
          </v-col>
          <v-col md="3">
            <BlogItemShortImage :blog="blog" />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row justify="center">
          <v-col md="3">
            <BlogItemShortImage :blog="blog" />
          </v-col>
          <v-col md="8">
            <BlogItemShortContent :blog="blog" />
          </v-col>
        </v-row>
      </template>

    </v-container>
  </div>
</template>

<script>

import datehelper from '@/helpers/datehelper'
import BlogItemShortContent from "@/components/Public/Blog/BlogItemShortContent.vue"
import BlogItemShortImage from "@/components/Public/Blog/BlogItemShortImage.vue"

export default {
  name: "Blog",
  components: {
    BlogItemShortContent,
    BlogItemShortImage,
  },
  data() {
    return {
      blogs: [],
    };
  },
  methods: {
    getLink(url) {
      return `/${this.$i18n.locale}/${url}`;
    },
    getDateDisplay(date) {
      return datehelper.getFullDateDisplay(date);
    },
    retrieveBlogs() {
      this.loading = true;
      this.$services.blog
        .getAll()
        .then((response) => {
          this.blogs = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveBlogs();
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>